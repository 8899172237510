import React from "react";
import axios from "axios";
import moment from "moment";
import queryString from "query-string";

import { SettingOutlined, TaobaoSquareFilled, UploadOutlined } from "@ant-design/icons";
import {
    Card,
    Button,
    Form,
    Input,
    Layout,
    message,
    Space,
    Modal,
    Divider,
    Tooltip,
    Row,
    Col,
    Slider,
} from "antd";
import {
    createFromIconfontCN,
    PauseOutlined,
    PlayCircleOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
} from "@ant-design/icons";

import SimuletDemo from "../Components/SimuletDemo";
import { FrameFetcher, PlayerManager } from "../core/player/PlayerManager";
import { CoordinateTransformer } from "../core/utils/coordutils";
import { CarLayerKeyFrameFactory } from "../Components/mapbox/FrameFactories";
import { AllDataConvertor } from "../core/converter/DataConverter";
import { CarInfo } from "../Components/mapbox/CarLayer";
import { MarkInfo } from "../Components/mapbox/MapBox";
import {
    FrameInfoFrameConstructor,
    StatefulCarFrameConstructor,
    StatefulPedsimFrameConstructor,
    StatefulPeopleFrameConstructor,
    StatisticsFrameConstructor,
    TrafficLightFrameConstructor,
} from "../core/converter/FrameConstructor";
import { StatisticsPlayer } from "../Components/Echarts";
import { CopyDataFrameFactory } from "../Components/mapbox/FrameFactories";
import { LayerControllerIcons } from "../Components/LayerControllerIcons";
import { LayerController } from "../core/LayerController";
import { FlyToOptions, LngLatLike } from "mapbox-gl";
import PubSub from 'pubsub-js';
import { apiUrl } from "../core/converter/RequestHub";

const speedMap = [1, 2, 5, 10, 30, 60, 120, 300];

const IconFont = createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/font_3397267_ttijnd1yjxq.js",
});

interface IWolongVisProps {
    twoSide: boolean;
    displayVersion: string;
}

export interface IDisplayLayer {
    car: boolean;
    road: boolean;
    stoppedRoad: boolean;
    elec: boolean;
    drainage: boolean;
    supply: boolean;
    flooded: boolean;
    comm: boolean;
    aoi_heatmap: boolean;
    population_heatmap: boolean;
    elec_demand: boolean;
    comm_demand: boolean;
    water_demand: boolean;
    comm_heatmap: boolean;
    comm_heatmap_micro: boolean;
    specialCar: boolean;
}

interface IWolongVisState {
    infoPanMin: boolean;

    allCurrent: number;
    allLoaded: number;
    allSliderShow: undefined | number;

    selectedCar: CarInfo | undefined;

    allStartFrame: number;
    allMaxFrame: number;
    playing: boolean;

    // 人流模拟poi名称
    pedsimName: string | undefined;

    // 车辆总数
    vehicleCount: number;

    // 视频显示计数器
    fakeSimCounter: number;
    fakeSimCurrent: undefined;

    // 是否加载模拟
    loaded: boolean;
    simName: string[] | undefined;
    inputLng: string;
    inputLat: string;


    // 显示的层
    // displayLayer: string[];
    displayLayers: IDisplayLayer;
    // 是否显示图表
    displayChart: boolean;
    showFilter: boolean;

    markers0: MarkInfo[];
    markers1: MarkInfo[];

    showLoadForm: boolean;
    //当前选中的图标
    currentChoseIcon: string;
    //选中对象
    chooseObj: {
        left: string
        right: string
    }
}

class WolongVis extends React.Component<IWolongVisProps, IWolongVisState> {
    speed = 1;
    trySelectCarId: string | undefined = undefined;
    lastZoomAndCenter?: FlyToOptions[];
    switchZoomAndCenter = {
        center: [116.46370939895223, 39.91435951242996],
        zoom: 15,
    };

    mapCenter = {
        lng: 116.39124329043085,
        lat: 39.906120097057055,
        // lng: 116.463709398952225,
        // lat: 39.91435951242996,
    };

    transformer = CoordinateTransformer.fromLngLat(this.mapCenter);
    queryParams: queryString.ParsedQuery<string>;
    timers: NodeJS.Timer[] = [];

    frameConstructors = {
        car: new StatefulCarFrameConstructor({
            kfFactory: new CarLayerKeyFrameFactory(this.transformer),
        }),
        people: new StatefulPeopleFrameConstructor({
            kfFactory: new CarLayerKeyFrameFactory(this.transformer),
        }),
        trafficLight: new TrafficLightFrameConstructor(),
        pedsim: new StatefulPedsimFrameConstructor({}),
        statistics: new StatisticsFrameConstructor(),
        frameInfo: new FrameInfoFrameConstructor(),
    };

    demo: React.RefObject<SimuletDemo>[];
    allLayerPlayerManager!: PlayerManager;
    allConvertor!: AllDataConvertor;
    doUpdateFrameReceivedHandler: any;
    layerController: LayerController = new LayerController();
    pid = "" //pubsub订阅id，用作取消订阅

    constructor(props: IWolongVisProps | Readonly<IWolongVisProps>) {
        super(props);
        this.demo = [];
        this.demo[0] = React.createRef();
        this.demo[1] = React.createRef();
        this.queryParams = queryString.parse(window.location.search);
        this.state = {
            infoPanMin: false,

            allCurrent: 0,
            allLoaded: 0,
            allSliderShow: undefined,

            selectedCar: undefined,

            allStartFrame: 0,
            allMaxFrame: 0,
            playing: false,

            pedsimName: undefined,

            vehicleCount: 0,

            fakeSimCounter: 0,

            fakeSimCurrent: undefined,

            loaded: false,
            simName: undefined,
            inputLng: "",
            inputLat: "",


            // displayLayer: this.layerOptions,
            displayLayers: {
                car: false,
                road: false,
                stoppedRoad: false,
                elec: false,
                drainage: false,
                supply: false,
                flooded: false,
                comm: false,
                aoi_heatmap: false,
                population_heatmap: false,
                elec_demand: false,
                comm_demand: false,
                water_demand: false,
                comm_heatmap: false,
                comm_heatmap_micro: false,
                specialCar: false,
            },
            displayChart: false,
            showFilter: false,

            markers0: [],
            markers1: [],

            showLoadForm: false,
            currentChoseIcon: "",
            chooseObj: {
                left: "",
                right: ""
            }
        };

        this.initAllPlayerManager();
    }

    clear() {
        this.clearPlayer();
        this.setState({
            allCurrent: 0,
            playing: false,
        });
    }

    clearPlayer() {
        this.allLayerPlayerManager.clear();
    }


    async componentDidMount() {
        const myToplevelSubscriber = (msg: any, data: any): void => {
            let chooseObj
            if (data?.currentIndex == 0) {
                chooseObj = {
                    left: data?.roadName,
                    right: this.state.chooseObj?.right
                }
            } else {
                chooseObj = {
                    left: this.state.chooseObj?.left,
                    right: data?.roadName,
                }
            }
            if (data?.type == "road-detail") {
                this.setState({
                    currentChoseIcon: "roadCanChose",
                    chooseObj
                })
            }
            if (data?.type == "aoi-detail") {
                this.setState({
                    currentChoseIcon: "aoiSelector",
                    chooseObj
                })
            }
        }
        //订阅主题
        this.pid = PubSub.subscribe('choseType', myToplevelSubscriber);//myToplevelSubscriber是一个函数

        this.layerController.init(this.allLayerPlayerManager, [this.demo[0].current!, this.demo[1].current!]);
        if (this.queryParams.job1 && this.queryParams.job2) {
            if (this.queryParams.norealtime === 'true') {

            } else {

                try {
                    const res1 = await axios.get(
                        `${process.env.REACT_APP_BACKEND_SIM_URL}/status/${this.queryParams.job1}`
                    );
                    const res2 = await axios.get(
                        `${process.env.REACT_APP_BACKEND_SIM_URL}/status/${this.queryParams.job2}`
                    );
                    if (res1) {
                        this.setState({
                            markers0: (res1.data as any).positions.map((v: any) => {
                                return {
                                    color: "red",
                                    coordinate: v,
                                    info: "none",
                                };
                            }),
                        });
                    }
                    if (res2) {
                        this.setState({
                            markers1: (res2.data as any).positions.map((v: any) => {
                                return {
                                    color: "red",
                                    coordinate: v,
                                    info: "none",
                                };
                            }),
                        });
                    }
                    if (res1 && res2) {
                        this.setState({
                            allMaxFrame: Math.min(
                                (res1.data as any).step,
                                (res2.data as any).step
                            ),
                        });

                        const t = setInterval(async () => {
                            const res1 = await axios.get(
                                `${process.env.REACT_APP_BACKEND_SIM_URL}/status/${this.queryParams.job1}`
                            );
                            const res2 = await axios.get(
                                `${process.env.REACT_APP_BACKEND_SIM_URL}/status/${this.queryParams.job2}`
                            );
                            if (res1 && res2) {
                                this.setState({
                                    allMaxFrame: Math.min(
                                        (res1.data as any).step,
                                        (res2.data as any).step
                                    ),
                                });
                            }
                        }, 3000);
                        this.timers.push(t);
                    }
                } catch (e) {
                    console.log(e);
                    return;
                }
            }

            await this.onLoadSim(
                [this.queryParams.job1 as string, this.queryParams.job2 as string],
                true,
                true
            );

        }
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.pid)
        this.timers.forEach((t) => {
            clearInterval(t);
        });
    }

    initAllPlayerManager() {
        this.allLayerPlayerManager = new PlayerManager(
            {},
            this.allLayerOnFetchRequest.bind(this),
            3
        );
        this.allLayerPlayerManager.onReceiveFrame = (frame) =>
            this.mayUpdateFrameReceived({ allLoaded: frame });
        this.allLayerPlayerManager.onPlayFrame = (frame) =>
            this.setState({ allCurrent: frame });
    }

    async onLoadSim(dbname: string[], world: boolean, stat: boolean) {
        if (this.state.loaded) {
            this.clear();
        }
        try {
            const frameCountToUpdate = {
                allMaxFrame: 0,
            };

            const sim: { data: any[] } = (await axios.get(
                `${apiUrl}/sims/${dbname[0]}`
            )).data as any;
            const _: { data: any[] } = (await axios.get(
                `${apiUrl}/sims/${dbname[1]}`
            )).data as any;

            frameCountToUpdate.allMaxFrame = sim.data[0].steps + sim.data[0].start;
            this.setState({ allStartFrame: sim.data[0].start });
            this.allConvertor = new AllDataConvertor(
                this.frameConstructors,
                frameCountToUpdate.allMaxFrame,
                {
                    road: this!.demo[0].current!.layers.roadLayer.keyFrameFactory,
                    water: this!.demo[0].current!.layers.waterLayer.keyFrameFactory,
                    flooded: this!.demo[0].current!.layers.floodedLayer.keyFrameFactory,
                    aoi: this!.demo[0].current!.layers.aoiLayer.keyFrameFactory,
                    demand: this!.demo[0].current!.layers.elec_demandLayer.keyFrameFactory,
                    roadMetric: new CopyDataFrameFactory(),
                    waterRoadMetric: new CopyDataFrameFactory(),
                    waterDrainageLinkMetric: new CopyDataFrameFactory(),
                    copyDataFactory: new CopyDataFrameFactory(),
                }
            );

            this.allLayerPlayerManager.set_start(sim.data[0].start);
            console.log("all created");

            if (world || stat) {
                message.success(`${dbname} - 载入成功。`);
                this.setState(frameCountToUpdate);
                this.setState({ loaded: true, simName: dbname });
            }
        } catch (err: any) {
            message.error(`载入失败。${err.message}`);
            console.log(err);
        }
    }

    getSubscribedCars() {
        const subscribed = [];
        if (this.state.selectedCar) subscribed.push(this.state.selectedCar.carId);
        if (this.trySelectCarId) subscribed.push(this.trySelectCarId);
        return subscribed;
    }

    onPlay() {
        if (!this.state.loaded) {
            message.error("请载入模拟文件");
        } else {
            this.allLayerPlayerManager.play();
            this.setState({ playing: true });
        }
    }

    onPlayOneFrame() {
        if (!this.state.loaded) {
            message.error("请载入模拟文件");
        } else {
            this.allLayerPlayerManager.playOneFrame();
        }
    }

    onPlaybackOneFrame() {
        if (!this.state.loaded) {
            message.error("请载入模拟文件");
        } else {
            this.allLayerPlayerManager.playbackOneFrame();
        }
    }

    onSetSpeed(speed: number) {
        this.speed = speed;
        this.allLayerPlayerManager.speed = this.speed;
    }

    onPause() {
        if (!this.state.loaded) {
            message.error("请载入模拟文件");
        } else {
            this.allLayerPlayerManager.pause();
            this.setState({ playing: false });
        }
    }

    onGoto(t: number) {
        if (this.state.loaded) {
            this.allLayerPlayerManager.clear_queue();
            this.allLayerPlayerManager.playFromTime(t, this.state.playing);
        }
    }

    onSelectCar(carId: string) {
        this.trySelectCarId = carId;
        this.demo.forEach((demo) => {
            demo.current!.layers.carLayer.selectCar(carId);
        });
    }

    onToggleInterp(enable: boolean) {
        this.demo.forEach((demo) => {
            demo.current!.layers.carLayer.toggleFrameInterp(enable);
        });
    }

    allLayerOnFetchRequest = (
        offset: number,
        count: number,
        fetcher: FrameFetcher
    ) => {
        let get_elements: string[] = this.layerController.getPlayerLayersToFetch();
        if (true) {
            get_elements.push("road-level");
            get_elements.push("comm-demand");
            get_elements.push("water-road-metric");
            // get_elements.push("water-drainage-link-metric");
            // get_elements.push("water-supply-link-metric");
            get_elements.push("event");
            // get_elements.push("elec-metric");
            // get_elements.push("traffic-metric");
            // get_elements.push("comm-metric");
            // get_elements.push("city-metric");
            // get_elements.push("demand-metric");
            get_elements.push("water-stat");
            get_elements.push("city-overview");
            get_elements.push("city-runtime");
            get_elements.push("traffic-stat");
            get_elements.push("comm-stat");
            get_elements.push("elec-stat");
        }

        this.allConvertor.onFetch(
            this.state.simName!,
            offset,
            count,
            get_elements,
            fetcher,
            this.demo.map((demo) => demo.current!.getMap()!.getZoom()),
            this.demo.map((demo) => {
                const bounds = demo.current!.getMap()!.getBounds();
                if (demo.current!.getMap()!.getZoom() > 12) {
                    // 将bounds向外扩充0.5个屏幕
                    const [ne, sw] = [bounds.getNorthEast(), bounds.getSouthWest()];
                    bounds.extend([ne.lng - (sw.lng - ne.lng) * 0.5, ne.lat - (sw.lat - ne.lat) * 0.5]);
                    bounds.extend([sw.lng + (sw.lng - ne.lng) * 0.5, sw.lat + (sw.lat - ne.lat) * 0.5]);
                }

                return bounds;
            }),
            this.speed
        );
    };
    mayUpdateFrameReceived(state: {
        allLoaded?: number;
        carLoaded?: number;
        roadLoaded?: number;
        elecLoaded?: number;
        waterLoaded?: number;
        floodedLoaded?: number;
    }) {
        Object.assign(this.state, state);
        if (!this.doUpdateFrameReceivedHandler) {
            console.log("set time out");
            this.doUpdateFrameReceivedHandler = setTimeout(() => {
                this.forceUpdate();
                this.doUpdateFrameReceivedHandler = undefined;
            }, 300);
        }
    }

    onFrameInfoChanged(info: any) {
        if (info && info.hasOwnProperty("vehicle_count")) {
            this.setState({ vehicleCount: info.vehicle_count });
        }
    }

    onToggleInfoPanMin() {
        const { infoPanMin } = this.state;
        this.setState({ infoPanMin: !infoPanMin });
    }

    onFakeSimClose() {
        this.setState({ fakeSimCurrent: undefined });
    }

    render() {
        const { allCurrent, allMaxFrame, allStartFrame, allSliderShow, playing } =
            this.state;
        // const { realtime } = this.props;

        // const { selectedCar } = this.state;
        // const selectedCarInfo = selectedCar ? (
        //   <div
        //     style={{
        //       backgroundColor: "lightcyan",
        //       position: "absolute",
        //       top: 130,
        //       width: 160,
        //       left: 20,
        //     }}
        //   >
        //     选中车辆： <br />
        //     ID：{selectedCar.carId} <br />
        //     经度: {selectedCar!.position!.lng.toFixed(6)} <br />
        //     纬度: {selectedCar!.position!.lat.toFixed(6)} <br />
        //     车道ID: {selectedCar.laneId} <br />
        //     desc: {selectedCar.description}
        //   </div>
        // ) : (
        //   <></>
        // );

        const loadForm = (
            <div style={{ float: "right", marginTop: 15 }}>
                {this.state.showLoadForm ? (
                    <Form
                        layout="inline"
                        onFinish={async (values) => {
                            await this.onLoadSim([values.dbname1, this.props.twoSide ? values.dbname2 : values.dbname1], true, true);
                        }}
                    >
                        <Form.Item name="dbname1">
                            <Input placeholder="模拟文件名1" />
                        </Form.Item>
                        {
                            this.props.twoSide && (
                                <Form.Item name="dbname2">
                                    <Input placeholder="模拟文件名2" />
                                </Form.Item>
                            )
                        }
                        <Button icon={<UploadOutlined />} type="primary" htmlType="submit">
                            载入
                        </Button>
                        <Button style={{ float: "right" }} icon={<SettingOutlined />} onClick={() => { this.setState({ showLoadForm: false }) }} />
                    </Form>
                ) : (
                    <Button style={{ float: "right" }} icon={<SettingOutlined />} onClick={() => { this.setState({ showLoadForm: true }) }} />
                )}
            </div>
        )

        const onElecFilter = (v: string) => {
            try {
                const n = v.split(",").map((x) => parseInt(x));
                this.demo.forEach((demo) => {
                    demo.current!.layers.elecLayer.filter(n);
                    demo.current!.layers.elecTopoLayer.filter(n);
                });
            } catch (e) {
                console.log(e);
            }
        };
        const onCommFilter = (v: string) => {
            try {
                const n = v.split(",").map((x) => parseInt(x));
                this.demo.forEach((demo) => {
                    demo.current!.layers.commLayer.filter(n);
                });
            } catch (e) {
                console.log(e);
            }
        };

        //可输入lat lng 跳转
        const inputJump = (<div className="input-jump">
            <Row gutter={12}>
                <Col span={8}><Input placeholder="请输入经度" className="dis-inline-block" onChange={(e) => {
                    this.setState({ inputLng: e.target.value })
                }} ></Input></Col>
                <Col span={8}
                ><Input placeholder="请输入纬度" className="dis-inline-block" onChange={(e) => {
                    this.setState({
                        inputLat: e.target.value
                    })
                }}></Input> </Col>
                <Col span={8}>
                    <Button onClick={() => {
                        if (this.state.inputLng && this.state.inputLat) {
                            let flyOption: FlyToOptions[] = [];
                            let obj: any = {
                                center: [parseFloat(this.state.inputLng), parseFloat(this.state.inputLat)],
                                zoom: 15
                            }
                            this.demo.forEach((d) => {
                                flyOption.push({
                                    speed: 1,
                                    center: obj.center as LngLatLike,
                                    zoom: obj.zoom,
                                })
                            })
                            this.demo.forEach((d, index) => d.current!.getMap()?.flyTo(flyOption[index]));
                        }
                    }} style={{ color: "#000", marginLeft: "20px" }}>跳转</Button>
                </Col>
            </Row>
        </div>);


        const layerButtons = (
            <div style={{}}>
                <Space direction="horizontal" size="small" style={{}}>
                    <Tooltip placement="right" title={"热点区域"}>
                        <Button
                            type={this.state.showFilter ? "link" : "text"}
                            icon={<IconFont type="icon-compass-discover-line" />}
                            onClick={() => {
                                let flyOption: FlyToOptions[] = [];
                                if (this.lastZoomAndCenter) {
                                    this.lastZoomAndCenter.forEach(lzc => flyOption.push({
                                        zoom: lzc.zoom,
                                        center: lzc.center,
                                        speed: 1,
                                    }));
                                    this.lastZoomAndCenter = undefined;
                                } else {
                                    this.lastZoomAndCenter = [];
                                    this.demo.forEach(d => {
                                        const map = d.current!.getMap()!;
                                        this.lastZoomAndCenter!.push({
                                            zoom: map.getZoom(),
                                            center: map.getCenter(),
                                        });
                                        flyOption.push({
                                            speed: 1,
                                            center: this.switchZoomAndCenter.center as LngLatLike,
                                            zoom: this.switchZoomAndCenter.zoom,
                                        })
                                    });
                                }
                                this.demo.forEach((d, index) => d.current!.getMap()?.flyTo(flyOption[index]));
                            }}
                        />
                    </Tooltip>
                    <Divider type="vertical" />
                    <LayerControllerIcons controller={this.layerController}
                        iconSelectCallback={(icon) => {
                            //icon选择，控制currnttab暂时不加
                            // this.setState({
                            //   currentChoseIcon: icon
                            // })
                        }}
                    />
                    <Divider type="vertical" />

                    <Tooltip placement="right" title={"显示筛选"}>
                        <Button
                            type={this.state.showFilter ? "link" : "text"}
                            icon={<IconFont type="icon-search-line" />}
                            onClick={() => {
                                this.setState({ showFilter: true });
                            }}
                        />
                    </Tooltip>
                </Space>
            </div>
        );

        const modalButtons = (
            <>
                <Modal
                    title={"筛选"}
                    visible={this.state.showFilter}
                    onCancel={() => {
                        this.setState({ showFilter: false });
                    }}
                    footer={null}
                >
                    <div style={{ margin: 20 }}>
                        <h4>电网筛选</h4>
                        <div style={{ marginRight: 30, marginLeft: 30 }}>
                            <Input.Search
                                placeholder="1,2,3"
                                allowClear
                                onSearch={onElecFilter}
                            />
                        </div>
                    </div>
                    <div style={{ margin: 20 }}>
                        <h4>通信网筛选</h4>
                        <div style={{ marginRight: 30, marginLeft: 30 }}>
                            <Input.Search
                                placeholder="1,2,3"
                                allowClear
                                onSearch={onCommFilter}
                            />
                        </div>
                    </div>
                </Modal>
                <Modal
                    title={"统计图表"}
                    visible={this.state.displayChart}
                    onCancel={() => {
                        this.setState({ displayChart: false });
                    }}
                    footer={null}
                >
                    <div
                        style={{
                            margin: 20,
                            // height: "calc(100vh - 400px)",
                            backgroundColor: "white",
                        }}
                    >
                        <StatisticsPlayer
                            playerManager={this.allLayerPlayerManager}
                            index={0}
                            currentTab={this.state?.currentChoseIcon}
                            chooseObj={this.state?.chooseObj}
                        />
                    </div>
                </Modal>
            </>
        );
        const { selectedCar } = this.state;
        const selectedCarInfo = selectedCar ? (
            <div
                style={{
                    backgroundColor: "lightcyan",
                    position: "absolute",
                    top: 130,
                    width: 160,
                    left: 20,
                }}
            >
                选中车辆： <br />
                ID：{selectedCar.carId} <br />
                经度: {selectedCar.position!.lng.toFixed(6)} <br />
                纬度: {selectedCar.position!.lat.toFixed(6)} <br />
                车道ID: {selectedCar.laneId} <br />
                desc: {selectedCar.description}
            </div>
        ) : (
            <></>
        );

        return (
            <div className="App">
                <Layout>
                    <Layout.Header>
                        <div style={{ float: "left" }}>
                            <img src="/logo-com.png" height={40} alt="Logo" />
                        </div>
                        <div
                            className="logo"
                            style={{ color: "white", display: "inline-block" }}
                        >
                            {/*Simulet - Vis - v{displayVersion}*/}
                        </div>
                        {/* {realtime || this.state.loaded ? <></> : loadForm} */}
                        {loadForm}
                    </Layout.Header>

                    <Layout.Content>
                        <Row>
                            <Col span={this.props.twoSide ? 4 : 6}>
                                <Card
                                    style={{ height: "calc(100vh - 136px)" }}
                                    bodyStyle={{ padding: "0" }}
                                >
                                    <div
                                        style={{
                                            margin: 20,
                                            // height: "calc(100vh - 400px)",
                                            backgroundColor: "white",
                                        }}
                                    >
                                        <StatisticsPlayer
                                            playerManager={this.allLayerPlayerManager}
                                            index={0}
                                            currentTab={this.state?.currentChoseIcon}
                                            chooseObj={this.state?.chooseObj}
                                        />
                                    </div>
                                </Card>
                            </Col>
                            <Col span={this.props.twoSide ? 16 : 18}>
                                {!this.props.twoSide && inputJump}
                                {layerButtons}
                                <Row>
                                    <Col span={this.props.twoSide ? 12 : 24}>
                                        <div>
                                            <SimuletDemo
                                                ref={this.demo[0]}
                                                index={0}
                                                // onCarSelected={(carInfo) => {
                                                //   console.log(carInfo);
                                                //   this.setState({
                                                //     selectedCar: carInfo.carId ? carInfo : undefined,
                                                //   });
                                                // }}
                                                allLayerPlayerManager={this.allLayerPlayerManager}
                                                mapCenter={this.mapCenter}
                                                transformer={this.transformer}
                                                markers={this.state.markers0}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={this.props.twoSide ? 12 : 0}>
                                        <div>
                                            <SimuletDemo
                                                ref={this.demo[1]}
                                                index={1}
                                                // onCarSelected={(carInfo) => {
                                                //   console.log(carInfo);
                                                //   this.setState({
                                                //     selectedCar: carInfo.carId ? carInfo : undefined,
                                                //   });
                                                // }}
                                                allLayerPlayerManager={this.allLayerPlayerManager}
                                                mapCenter={this.mapCenter}
                                                transformer={this.transformer}
                                                markers={this.state.markers1}
                                            />

                                            {modalButtons}

                                            {/* {selectedCarInfo} */}
                                        </div>
                                    </Col>
                                </Row>
                                <div
                                    style={{
                                        height: "5vh",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Space size={"large"}>
                                        {playing ? (
                                            <Button
                                                icon={<PauseOutlined />}
                                                onClick={() => this.onPause()}
                                            />
                                        ) : (
                                            <Button
                                                icon={<PlayCircleOutlined />}
                                                onClick={() => this.onPlay()}
                                            />
                                        )}
                                        <Button
                                            icon={<StepBackwardOutlined />}
                                            onClick={() => this.onPlaybackOneFrame()}
                                        />
                                        <Button
                                            icon={<StepForwardOutlined />}
                                            onClick={() => this.onPlayOneFrame()}
                                        />

                                        <span>播放速度：</span>
                                        <Slider
                                            min={0}
                                            max={speedMap.length - 1}
                                            tipFormatter={value => speedMap[value ?? 0]}
                                            onChange={(value) => {
                                                this.onSetSpeed(speedMap[value ?? 0]);
                                            }}
                                            defaultValue={0}
                                            style={{ width: 60 }}
                                        />
                                        <span>跳转：</span>
                                        <Form
                                            layout="inline"
                                            onFinish={(values) => {
                                                const t = Number(values.goTime);
                                                if (Number.isNaN(t)) {
                                                    message.error("请输入有效数字");
                                                } else {
                                                    this.onGoto(t);
                                                }
                                            }}
                                        >
                                            {/*<span>转到帧&nbsp;</span>*/}
                                            <Form.Item name="goTime">
                                                <Input
                                                    size="small"
                                                    style={{ width: 80 }}
                                                    placeholder="帧序号"
                                                />
                                            </Form.Item>
                                            <Button htmlType="submit">转到</Button>
                                        </Form>
                                        <div>
                                            播放进度：
                                            {moment("00:00:00", "HH:mm:ss")
                                                .add(allCurrent, "seconds")
                                                .format("HH:mm:ss")}
                                            /
                                            {moment("00:00:00", "HH:mm:ss")
                                                .add(allMaxFrame, "seconds")
                                                .format("HH:mm:ss")}
                                        </div>
                                    </Space>
                                </div>
                                <div>
                                    <Row justify="space-around" align="middle">
                                        <Col span={2}>
                                            <span>进度</span>
                                        </Col>
                                        <Col span={20}>
                                            <Slider
                                                disabled={!this.state.loaded}
                                                min={allStartFrame}
                                                max={allMaxFrame}
                                                value={allSliderShow || allCurrent}
                                                onChange={(value) => {
                                                    this.setState({ allSliderShow: value });
                                                }}
                                                onAfterChange={(value) => {
                                                    const t = Number(value);
                                                    if (Number.isNaN(t)) {
                                                        message.error("请输入有效数字");
                                                    } else {
                                                        this.onGoto(t);
                                                    }

                                                    this.setState({ allSliderShow: undefined });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col span={4}>
                                <Card
                                    style={{ height: "calc(100vh - 136px)" }}
                                    bodyStyle={{ padding: "0" }}
                                >
                                    <div
                                        style={{
                                            margin: 20,
                                            // height: "calc(100vh - 400px)",
                                            backgroundColor: "white",
                                        }}
                                    >
                                        <StatisticsPlayer
                                            playerManager={this.allLayerPlayerManager}
                                            index={1}
                                            currentTab={this.state?.currentChoseIcon}
                                            chooseObj={this.state?.chooseObj}
                                        />
                                    </div>
                                    {/* <ControlPan {...controlPanProps} show={this.state.loaded} /> */}
                                </Card>
                            </Col>
                        </Row>
                    </Layout.Content>
                </Layout>
            </div>
        );
    }
}

export default WolongVis;
