import {AbstractPlayer, ICrowdPlayer} from "./AbstractPlayer";
import {ICustomDataKeyFrame, IKeyFrame, ITypedDataKeyFrame} from "./KeyFrame";

type RoadDetail=any

export class AoiDetailPlayer extends AbstractPlayer implements ICrowdPlayer {

    lastFrameTime?: number;
  
    constructor(private onDataChanged: (data: RoadDetail) => void) {
      super();
    }
  
    appendKeyFrames(keyFrames: { [p: string]: IKeyFrame }): any {
      this.enqueueKeyFrame(keyFrames.info);
    }
  
    play(timing: number) {
      // debugger;
      const keyFrame = this.getCurrentKeyFrame(timing) as ITypedDataKeyFrame<RoadDetail>;
      if (keyFrame && this.lastFrameTime !== keyFrame.timing) {
        this.onDataChanged(keyFrame.data);
      }
      this.lastFrameTime = keyFrame ? keyFrame.timing : undefined;
    }
  
  }
  