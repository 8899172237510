import {PlayerManager} from "./player/PlayerManager";
import SimuletDemo from "../Components/SimuletDemo";

export enum ELayerIconState {
    HIDDEN,
    VISIBLE,
    DISABLED
}

interface ILayerState {
    iconState: ELayerIconState,
    context: any,
}

interface IControlledLayer {
    name: string,
    displayName: string,
    icon: string,
    demoRelatedLayers: string[] | ((state: ILayerState) => string[]),
    playerRelatedLayers: string[],
    fetchRelatedNames: string[] | ((state: ILayerState) => string[]),
    exclusiveLayers?: string[],
    toggleFunction?: (
        prevState: ILayerState, nextState: ILayerState, player: PlayerManager, demos: SimuletDemo[], layerController: LayerController
    ) => ILayerState,
    defaultContext?: any,
}

export class LayerController {
    private demos?: SimuletDemo[];
    private visibilityChangedObservers: {[name: string]: (sender: LayerController) => void} = {};
    private playerManager?: PlayerManager;
    demoControlledLayers = [
        'carLayer',
        'roadLayer',
        'stoppedRoadLayer',
        'elecLayer',
        'elecTopoLayer',
        'supplyLayer',
        'waterLayer',
        'floodedLayer',
        'commLayer',
        'aoiLayer',
        'populationHeatmapLayer',
        'commHeatmapLayer',
        'commMicroHeatmapLayer',
        'elec_demandLayer',
        'water_demandLayer',
        'comm_demandLayer',
        'demandLogoLayer',
        'specialCarLayer',
        "roadCanChoseLayer",
        "aoiSelectorLayer"
    ];
    controlledLayers: IControlledLayer[] = [
        {
            name: 'test',
            displayName: "宏观-级联失效",
            icon: 'icon-notification-off-line',
            demoRelatedLayers: ['elecLayer', 'supplyLayer', 'commLayer', 'waterLayer'],
            playerRelatedLayers: ['elec', 'supply', 'comm', 'drainage'],
            fetchRelatedNames: [
                'elec-nodes', 'water-supply-link', 'water-drainage-link',
                'comm-node'
            ],
            exclusiveLayers: ['elec', 'comm', 'supply'],
            toggleFunction: ((prevState, nextState, player, demos, layerController) => {
                demos.forEach(demo => {
                    demo.layers.elecLayer.toggleBreakDown(nextState.iconState === ELayerIconState.VISIBLE);
                    demo.layers.supplyLayer.toggleBreakDown(nextState.iconState === ELayerIconState.VISIBLE);
                    demo.layers.commLayer.toggleBreakDown(nextState.iconState === ELayerIconState.VISIBLE);
                    demo.layers.waterLayer.toggleBreakDown(nextState.iconState === ELayerIconState.VISIBLE);
                });
                return nextState;
            })
        },
        {
            name: 'car',
            displayName: "交通",
            icon: 'icon-car-line',
            demoRelatedLayers: ['carLayer'],
            playerRelatedLayers: ['car'],
            fetchRelatedNames: ['cars', 'people', 'traffic-lights'],
        },
        {
            name: 'road',
            displayName: "道路",
            icon: 'icon-road-map-line',
            demoRelatedLayers: ['roadLayer'],
            playerRelatedLayers: ['road'],
            fetchRelatedNames: ['road-state'],
        },
        {
            name: 'road_stopped',
            displayName: "道路-已失效",
            icon: 'icon-road-map-line',
            demoRelatedLayers: ['stoppedRoadLayer'],
            playerRelatedLayers: ['stoppedRoad'],
            fetchRelatedNames: ['road-state'],
        },
        {
            name: 'elec',
            displayName: "电网",
            icon: 'icon-flashlight-line',
            demoRelatedLayers: ['elecLayer', 'elecTopoLayer'],
            playerRelatedLayers: ['elec'],
            fetchRelatedNames: ['elec-nodes'],
        },
        {
            name: 'supply',
            displayName: "供水网",
            icon: 'icon-settings-fill',
            demoRelatedLayers: ['supplyLayer'],
            playerRelatedLayers: ['supply'],
            fetchRelatedNames: ['water-supply-link'],
        },
        {
            name: 'drainage',
            displayName: "排水网",
            icon: 'icon-settings-line',
            demoRelatedLayers: ['waterLayer'],
            playerRelatedLayers: ['drainage'],
            fetchRelatedNames: ['water-drainage-link'],
        },
        {
            name: 'flooded',
            displayName: "积水",
            icon: 'icon-drop-line',
            demoRelatedLayers: ['floodedLayer'],
            playerRelatedLayers: ['flooded'],
            fetchRelatedNames: ['water-road'],
        },
        {
            name: 'comm',
            displayName: "通信",
            icon: 'icon-wifi-line',
            demoRelatedLayers: ['commLayer'],
            playerRelatedLayers: ['comm'],
            fetchRelatedNames: ['comm-node'],
        },
        {
            name: 'aoi',
            displayName: "AOI热力图",
            icon: 'icon-star-line',
            demoRelatedLayers: ['aoiLayer'],
            playerRelatedLayers: ['aoi_heatmap'],
            fetchRelatedNames: ['aoi'],
        },
        {
            name: 'population_heatmap',
            displayName: "人流热力图-微观",
            icon: 'icon-star-line',
            demoRelatedLayers: ['populationHeatmapLayer'],
            playerRelatedLayers: ['population_heatmap'],
            fetchRelatedNames: ['heatmap'],
        },
        {
            name: 'comm_heatmap',
            displayName: "通信热力图",
            icon: 'icon-base-station-line',
            demoRelatedLayers: ['commHeatmapLayer'],
            playerRelatedLayers: ['comm_heatmap'],
            fetchRelatedNames: ['comm-signal'],
        },
        {
            name: 'comm_heatmap_micro',
            displayName: "通信热力图-微观",
            icon: 'icon-base-station-line',
            demoRelatedLayers: ['commMicroHeatmapLayer'],
            playerRelatedLayers: ['comm_heatmap_micro'],
            fetchRelatedNames: ['comm-signal-micro'],
        },
        {
            name: 'elec_demand',
            displayName: "电力需求不满足情况",
            icon: 'icon-home-gear-line',
            demoRelatedLayers: ['elec_demandLayer'],
            playerRelatedLayers: ['elec_demand'],
            fetchRelatedNames: ['elec-aois'],
        },
        {
            name: 'water_demand',
            displayName: "供水需求不满足情况",
            icon: 'icon-home-7-line',
            demoRelatedLayers: ['water_demandLayer'],
            playerRelatedLayers: ['water_demand'],
            fetchRelatedNames: ['water-aoi'],
        },
        {
            name: 'comm_demand',
            displayName: "通信需求不满足情况",
            icon: 'icon-home-wifi-line',
            demoRelatedLayers: ['comm_demandLayer'],
            playerRelatedLayers: ['comm_demand'],
            fetchRelatedNames: ['comm-aoi'],
        },
        {
            name: 'logo_demand',
            displayName: "AOI需求不满足-微观",
            icon: 'icon-home-wifi-line',
            demoRelatedLayers: ['demandLogoLayer'],
            playerRelatedLayers: ['elec_demand', 'water_demand', 'comm_demand'],
            fetchRelatedNames: ['elec-aois', 'water-aoi', 'comm-aoi'],
        },
        {
            name: 'special_car',
            displayName: "抢修车",
            icon: 'icon-car-washing-line',
            demoRelatedLayers: ['specialCarLayer'],
            playerRelatedLayers: ['specialCar'],
            fetchRelatedNames: ['special-car-trajectory'],
        },
        {
            name: 'roadCanChose',
            displayName: "道路点选",
            // icon: 'icon-daolu',
            icon: 'icon-road-map-line',
            demoRelatedLayers: ['roadCanChoseLayer'],
            playerRelatedLayers: [],
            fetchRelatedNames: ["road-detail"],
        },
        {
            name: 'aoiSelector',
            displayName: "AOI点选",
            // icon: 'icon-quyu',
            icon: 'icon-home-wifi-line',
            demoRelatedLayers: ['aoiSelectorLayer'],
            playerRelatedLayers: [],
            fetchRelatedNames: ["aoi-detail"],
        },
    ]

    layerStates: {[layer: string]: ILayerState};

    constructor() {
        this.layerStates = Object.fromEntries(
            this.controlledLayers.map(
                layer => ([layer.name, {context: layer.defaultContext, iconState: ELayerIconState.HIDDEN}])
            )
        );
    }

    init(playerManager: PlayerManager, demos: SimuletDemo[]) {
        this.playerManager = playerManager;
        this.demos = demos;
    }

    registerOrUnregisterLayerVisibilityChangedObserver(name: string, f?: (sender: LayerController) => void) {
        if (f) {
            this.visibilityChangedObservers[name] = f;
        } else {
            delete this.visibilityChangedObservers[name];
        }
    }

    getControlledLayerState(): {layer: IControlledLayer, state: ILayerState}[] {
        return this.controlledLayers.map(layer => ({layer, state: this.layerStates[layer.name]}));
    }

    toggleLayer(layerName: string) {
        if (this.layerStates[layerName] && this.layerStates[layerName].iconState !== ELayerIconState.DISABLED) {
            const prevState = Object.assign({}, this.layerStates[layerName]);
            let {iconState} = this.layerStates[layerName];
            if (iconState === ELayerIconState.VISIBLE) {
                iconState = ELayerIconState.HIDDEN;
            } else {
                iconState = ELayerIconState.VISIBLE;
            }
            let curState = this.layerStates[layerName];
            curState.iconState = iconState;

            const [layer] = this.findLayerByName(layerName);

            if (layer?.exclusiveLayers) {
                layer.exclusiveLayers.forEach(el => this.layerStates[el].iconState = iconState === ELayerIconState.VISIBLE ? ELayerIconState.DISABLED : ELayerIconState.HIDDEN);
            }

            if (layer!.toggleFunction) {
                curState = layer!.toggleFunction(prevState, curState, this.playerManager!, this.demos!, this);
            }
            // const relatedLayers = (typeof layer!.playerRelatedLayers === 'function') ? layer!.playerRelatedLayers(curState) : layer!.playerRelatedLayers!;
            //
            // this.demos!.forEach(demo => {
            //     relatedLayers.forEach(l => {
            //         // @ts-ignore
            //         const demoLayer = demo.layers[l];
            //         demoLayer.setLayerVisibility(curState.iconState === ELayerIconState.VISIBLE);
            //     });
            // });
            this.layerStates[layerName] = curState;
            this.updateLayersVisibility();
        }
    }

    findLayerByName(layerName: string): [IControlledLayer | undefined, number] {
        const index = this.controlledLayers.findIndex((v) => v.name === layerName);
        if (index >= 0) {
            return [this.controlledLayers[index], index];
        }
        return [undefined, -1];
    }

    updateContext(layerName: string, context: any) {
        if (this.layerStates[layerName]) {
            if (this.layerStates[layerName].context !== undefined && context !== undefined) {
                this.layerStates[layerName].context = Object.assign(this.layerStates[layerName].context, context);
            } else {
                this.layerStates[layerName].context = context;
            }
            this.updateLayersVisibility();
        }
    }

    updateLayersVisibility() {
        // 图层
        const layersToShow = new Set<string>();

        // PlayerManagerLayer
        const playerManagerLayersToPlay = new Set<string>();

        this.controlledLayers.forEach(layer => {
            const layerState = this.layerStates[layer.name];
            if (layerState.iconState === ELayerIconState.VISIBLE) {
                this.getDemoRelatedLayers(layer.name).forEach(lts => layersToShow.add(lts));
                layer.playerRelatedLayers.forEach(pl => playerManagerLayersToPlay.add(pl));
            }
        });

        const layersToHide = this.demoControlledLayers.filter(l => !layersToShow.has(l));

        // 图层控制
        layersToShow.forEach(layerName => {
            this.demos?.forEach(demo => {
                // @ts-ignore
                const demoLayer = demo.layers[layerName];
                demoLayer.setLayerVisibility(true);
            })
        });

        layersToHide.forEach(layerName => {
            this.demos?.forEach(demo => {
                // @ts-ignore
                const demoLayer = demo.layers[layerName];
                demoLayer.setLayerVisibility(false);
            })
        });

        Object.values(this.visibilityChangedObservers).forEach(f => f(this));

        // PlayerManager使能
        this.playerManager?.displayLayerSet(playerManagerLayersToPlay);
    }

    getPlayerLayersToFetch(): string[] {
        const layersToFetch = new Set<string>();
        this.controlledLayers.forEach(layer => {
            const state = this.layerStates[layer.name];
            if (state.iconState === ELayerIconState.VISIBLE) {
                const playerLayers = this.getPlayerManagerRelatedLayers(layer.name);
                playerLayers.forEach(l => layersToFetch.add(l));
            }
        });
        return Array.from(layersToFetch);
    }

    getDemoRelatedLayers(layerName: string): string[] {
        if (this.layerStates[layerName]) {
            const [layer] = this.findLayerByName(layerName);
            const state = this.layerStates[layerName];
            return (typeof layer!.demoRelatedLayers === 'function') ? layer!.demoRelatedLayers(state) : layer!.demoRelatedLayers!;
        }
        return [];
    }

    getPlayerManagerRelatedLayers(layerName: string): string[] {
        if (this.layerStates[layerName]) {
            const [layer] = this.findLayerByName(layerName);
            const state = this.layerStates[layerName];
            return (typeof layer!.fetchRelatedNames === 'function') ? layer!.fetchRelatedNames(state) : layer!.fetchRelatedNames!;
        }
        return [];
    }

}