import React, { Component } from "react";
import { EventData, LngLat, LngLatBounds, MapMouseEvent } from "mapbox-gl";
import mapboxgl from "mapbox-gl";
import {
  Layout,
  Table,
  Button,
  Select,
  Card,
  Input,
  Tabs,
  Typography,
  message,
} from "antd";
import axios from "axios";

import "mapbox-gl/dist/mapbox-gl.css";


const hardCodedPlans: {[plan: string]: number[][]} = {
  js_small: [
    [
      116.24807794914626, 39.81500025838231
    ],
    [
      116.3535, 39.8035
    ],
    [
      116.53308853326025, 39.87399521278789
    ]
  ],
  js_big: [
    [
      116.24807794914626, 39.81500025838231
    ],
    [
      116.46432971390678, 39.807622976871635
    ],
    [
      116.53260237356886, 39.81426136900458
    ]
  ]
}

interface IMapboxRegionSelectorProps {
  onJobSelected: (jobName: string) => void;
  side: "left" | "right";
}

interface IMapboxRegionSelectorStates {
  displayMarkers: any[];
  isCreating: boolean;
  jobName: string;
  presetData: ISetData[];
  presetOptions: { recommended: any[]; custom: any[] };
}

interface ISetData {
  name: string;
  positions: any[];
  recommended: boolean;
}

mapboxgl.accessToken =
  "pk.eyJ1IjoiZmh5ZHJhbGlzayIsImEiOiJja3VzMWc5NXkwb3RnMm5sbnVvd3IydGY0In0.FrwFkYIMpLbU83K9rHSe8w";
// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

interface MapboxProps {
  lng: number;
  lat: number;
  zoom: number;
  pitch?: number;
  buildingLayer: boolean;
  externalLayers: any[];
  sources?: { name: string; data: any }[];
  style: any;
}

class Mapbox extends Component<MapboxProps> {
  mapContainer: React.RefObject<any>;
  map?: mapboxgl.Map;

  constructor(props: MapboxProps) {
    super(props);
    const { lng, lat, zoom, pitch } = props;
    this.state = {
      lng: lng,
      lat: lat,
      zoom: zoom,
      pitch: pitch,
    };
    this.mapContainer = React.createRef();
  }

  async componentDidMount() {
    const { lng, lat, zoom, pitch } = this.props;
    // const center = this.spline.getPoint(0.01)
    // console.log(new mapboxgl.MercatorCoordinate(center.x, center.y).toLngLat())
    this.map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
      pitch: pitch,
    });
    this.map.on("load", () => this.onMapLoad());
  }

  async onMapLoad() {
    console.log("map loaded.");
    if (this.props.buildingLayer) this.addBuildingLayer();
    if (this.props.sources) {
      this.props.sources.forEach(({ name, data }) =>
        this.map!.addSource(name, data)
      );
    }
    this.props.externalLayers.forEach((layer) => {
      this.map!.addLayer(layer, "waterway-label");
    });
  }

  addBuildingLayer() {
    this.map!.addLayer(
      {
        id: "add-3d-buildings",
        source: "composite",
        "source-layer": "building",
        filter: ["==", "extrude", "true"],
        type: "fill-extrusion",
        minzoom: 15,
        paint: {
          "fill-extrusion-color": "#fff",

          // Use an 'interpolate' expression to
          // add a smooth transition effect to
          // the buildings as the user zooms in.
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["*", ["get", "height"], 5],
          ],
          "fill-extrusion-base": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "min_height"],
          ],
          "fill-extrusion-opacity": 0.6,
        },
      },
      "waterway-label"
    );
  }

  render() {
    const { style } = this.props;
    return (
      <div>
        <div ref={this.mapContainer} className="map-container" style={style} />
      </div>
    );
  }
}

class MapboxRegionSelector extends Component<
  IMapboxRegionSelectorProps,
  IMapboxRegionSelectorStates
> {
  mapbox = React.createRef<Mapbox>();

  selectStart?: LngLat;
  selectEnd?: LngLat;
  selecting: boolean = false;
  markers: mapboxgl.Marker[];
  rectLayer = {
    id: "point",
    type: "fill",
    source: "point",
    layout: {},
    paint: {
      "fill-color": "#ff0000",
      "fill-opacity": 0.5,
    },
  };

  initialSource = {
    type: "geojson",
    data: {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [],
      },
    },
  };
  constructor(props: IMapboxRegionSelectorProps) {
    super(props);
    this.markers = [];
    this.state = {
      displayMarkers: [],
      isCreating: false,
      jobName: "",
      presetData: [
        {
          name: "test",
          positions: [
            [116.309, 39.9712],
            [116.4134, 39.9044],
          ],
          recommended: true,
        },
      ],
      presetOptions: { recommended: [], custom: [] },
    };
  }

  clear() {
    this.selecting = false;
    this.selectStart = undefined;
    this.selectEnd = undefined;
  }

  updateSelectingMode() {
    this.clear();
  }

  collectData() {
    if (this.markers.length === 0) {
      message.error("请选取打击点或者加载预设");
    }
    return {
      positions: this.markers.map((marker) => marker.getLngLat().toArray()),
    };
  }

  genTableData(markers: mapboxgl.Marker[]) {
    return markers.map((m, i) => {
      return {
        key: i,
        pos: `${m.getLngLat().lng.toFixed(4)} ${m.getLngLat().lat.toFixed(4)}`,
      };
    });
  }
  loadPreset(set: ISetData) {
    this.setState({ jobName: set.name });
    this.addPresetMarkers(set.positions.map((p) => new LngLat(p[0], p[1])));
  }

  addPresetMarkers(pos: LngLat[]) {
    this.markers.forEach((m) => m.remove());
    let new_markers = pos.map((p) => {
      const assignBtn = document.createElement("div");
      assignBtn.innerHTML = `<a style="color: red"> 删除</a>`;

      const marker = new mapboxgl.Marker({ color: "red" }).setLngLat(p);
      assignBtn.addEventListener("click", (e) => {
        this.delMarker_byv(marker);
      });

      const popup = new mapboxgl.Popup()
        .setDOMContent(assignBtn)
        .addTo(this.mapbox.current!.map!);
      marker.setPopup(popup).addTo(this.mapbox.current!.map!);
      return marker;
    });
    this.markers = new_markers;
    this.setState({ displayMarkers: this.genTableData(this.markers) });
  }

  delMarker(i: number) {
    this.markers[i].remove();
    this.markers.splice(i, 1);
    this.setState({
      displayMarkers: this.genTableData(this.markers),
    });
  }
  delMarker_byv(m: mapboxgl.Marker) {
    const i = this.markers.indexOf(m);
    this.delMarker(i);
  }
  delAllMarker() {
    this.markers.forEach((m) => m.remove());
    this.markers = [];
    this.setState({
      displayMarkers: this.genTableData(this.markers),
    });
  }

  onMouseDown(e: MapMouseEvent & EventData) {
    if (this.state.isCreating) {
      const assignBtn = document.createElement("div");
      assignBtn.innerHTML = `<a style="color: red"> 删除</a>`;

      const marker = new mapboxgl.Marker({ color: "red" }).setLngLat(e.lngLat);
      assignBtn.addEventListener("click", (e) => {
        this.delMarker_byv(marker);
      });

      const popup = new mapboxgl.Popup()
        .setDOMContent(assignBtn)
        .addTo(this.mapbox.current!.map!);
      marker.setPopup(popup).addTo(this.mapbox.current!.map!);

      this.markers.push(marker);
      this.setState({ displayMarkers: this.genTableData(this.markers) });
    }
  }

  async componentDidMount() {
    this.updateSelectingMode();
    this.mapbox.current!.map!.doubleClickZoom.disable();
    this.mapbox.current!.map!.on("dblclick", (e) => this.onMouseDown(e));
    this.mapbox.current!.map!.on("contextmenu", (e) => {});
    this.mapbox.current!.map!.dragRotate.disable();
    this.mapbox.current!.map!.touchZoomRotate.disable();
  }

  componentDidUpdate(
    prevProps: Readonly<IMapboxRegionSelectorProps>,
    prevState: Readonly<IMapboxRegionSelectorStates>
  ) {
    this.updateSelectingMode();
  }

  render() {
    const sider = (
      <Layout.Sider width={"17vw"} style={{ backgroundColor: "white" }}>
        <Card style={{ height: "80vh" }}>
          <h2>设置{this.props.side === "left" ? "左" : "右"}侧场景</h2>
          <Tabs
            onTabClick={(key) => {
              this.delAllMarker();
              this.setState({ jobName: "" });
              if (key === "已有方案") this.setState({ isCreating: false });
              else if (key === "新建方案") this.setState({ isCreating: true });
            }}
          >
            <Tabs.TabPane tab={"已有模拟"} key={"已有方案"}>
              <Typography>
                <Typography.Title level={4}>已有方案</Typography.Title>
                <Typography.Paragraph>
                  选取推荐的预设方案，或者选取之前保存的自定义方案
                </Typography.Paragraph>
              </Typography>
              <div style={{ marginBottom: 20 }}>
                <h3>选取模拟</h3>
                <Select
                  options={[
                    {
                      label: "推荐",
                      options: [
                          {label: "推荐选点1", value: "js_big"},
                      ],
                    },
                    {
                      label: "用户",
                      options: [
                        {label: "用户选点1", value: "js_small"},
                      ]
                    },
                  ]}
                  style={{ width: 200 }}
                  placeholder="选取方案"
                  onSelect={(value: string) => {
                    this.setState({ jobName: value });
                    this.addPresetMarkers(hardCodedPlans[value].map(p => new LngLat(p[0], p[1])));
                    this.props.onJobSelected(value);
                  }}
                />
              </div>
              <h3>坐标数据</h3>
              <Table
                style={{ marginTop: 20, marginBottom: 20 }}
                columns={[{ title: "坐标", key: "pos", dataIndex: "pos" }]}
                dataSource={this.state.displayMarkers}
                pagination={false}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={"新建模拟"} key={"新建方案"}>
              <Typography style={{ marginBottom: 20 }}>
                <Typography.Title level={4}>新建方案</Typography.Title>
                <Typography.Paragraph>
                  在地图上双击添加打击点，点击创建的点或者在数据展示栏中可以删除
                  <br />
                  可以选择保存方案以后使用，也可以直接创建模拟
                </Typography.Paragraph>
              </Typography>

              <Input
                style={{ width: 150 }}
                placeholder="方案名称"
                onChange={(e) => {
                  this.setState({ jobName: e.target.value });
                }}
              />

              <Button
                type="primary"
                onClick={async () => {
                  let data = this.collectData() as any;
                  if (this.state.jobName) {
                    data.name = this.state.jobName;
                    data.recommended = false;
                  }
                  const res = await axios.post(
                    `${process.env.REACT_APP_BACKEND_SIM_URL}/add-plan`,
                    data
                  );
                  if (res.status === 200) {
                    message.success("保存成功，请刷新页面");
                  } else if (res.status === 500) {
                    message.error("命名重复");
                  }
                }}
              >
                保存方案
              </Button>

              <Table
                style={{ marginTop: 20, marginBottom: 20 }}
                columns={[
                  { title: "坐标", key: "pos", dataIndex: "pos" },
                  {
                    title: "操作",
                    key: "op",
                    dataIndex: "op",
                    render: (v, r, i) => {
                      return (
                        <Button
                          type="text"
                          danger
                          onClick={() => {
                            this.delMarker(i);
                          }}
                        >
                          删除
                        </Button>
                      );
                    },
                  },
                ]}
                dataSource={this.state.displayMarkers}
                pagination={false}
              />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </Layout.Sider>
    );
    return (
      <div>
        <Layout>
          {this.props.side === "left" ? sider : <></>}
          <Layout.Content>
            <Mapbox
              ref={this.mapbox}
              lng={116.49524329043085}
              lat={39.906120097057055}
              zoom={10}
              pitch={0}
              buildingLayer={false}
              externalLayers={[this.rectLayer]}
              sources={[{ name: "point", data: this.initialSource }]}
              style={{ height: "80vh", margin: 10 }}
            />
          </Layout.Content>
          {this.props.side === "right" ? sider : <></>}
        </Layout>
      </div>
    );
  }
}

export default MapboxRegionSelector;
