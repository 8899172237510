import React, {Component} from "react";
import { LngLat } from "mapbox-gl";
import mapboxgl from "mapbox-gl";
import {
    Layout,
    Button,
    Select,
    Card,
    Input,
    Tabs,
    Typography,
    Slider, Form,
} from "antd";

import "mapbox-gl/dist/mapbox-gl.css";

interface IMapboxRegionSelectorProps {
    onSelectComplete?: (jobName: string) => void;
    side: "left" | "right";
}

interface IMapboxRegionSelectorStates {
    rainfall: number;
    isCreating: boolean;
    jobName: string;
    presetData: ISetData[];
    presetOptions: { recommended: any[]; custom: any[] };
}

interface ISetData {
    name: string;
    positions: any[];
    recommended: boolean;
}

mapboxgl.accessToken =
    "pk.eyJ1IjoiZmh5ZHJhbGlzayIsImEiOiJja3VzMWc5NXkwb3RnMm5sbnVvd3IydGY0In0.FrwFkYIMpLbU83K9rHSe8w";
// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
mapboxgl.workerClass =
    // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
    require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

interface MapboxProps {
    lng: number;
    lat: number;
    zoom: number;
    pitch?: number;
    buildingLayer: boolean;
    externalLayers: any[];
    sources?: { name: string; data: any }[];
    style: any;
}

class Mapbox extends Component<MapboxProps> {
    mapContainer: React.RefObject<any>;
    map?: mapboxgl.Map;

    constructor(props: MapboxProps) {
        super(props);
        const {lng, lat, zoom, pitch} = props;
        this.state = {
            lng: lng,
            lat: lat,
            zoom: zoom,
            pitch: pitch,
        };
        this.mapContainer = React.createRef();
    }

    async componentDidMount() {
        const {lng, lat, zoom, pitch} = this.props;
        // const center = this.spline.getPoint(0.01)
        // console.log(new mapboxgl.MercatorCoordinate(center.x, center.y).toLngLat())
        this.map = new mapboxgl.Map({
            container: this.mapContainer.current,
            style: "mapbox://styles/mapbox/streets-v11",
            center: [lng, lat],
            zoom: zoom,
            pitch: pitch,
        });
        this.map.on("load", () => this.onMapLoad());
    }

    async onMapLoad() {
        console.log("map loaded.");
        if (this.props.buildingLayer) this.addBuildingLayer();
        if (this.props.sources) {
            this.props.sources.forEach(({name, data}) =>
                this.map!.addSource(name, data)
            );
        }
        this.props.externalLayers.forEach((layer) => {
            this.map!.addLayer(layer, "waterway-label");
        });
    }

    addBuildingLayer() {
        this.map!.addLayer(
            {
                id: "add-3d-buildings",
                source: "composite",
                "source-layer": "building",
                filter: ["==", "extrude", "true"],
                type: "fill-extrusion",
                minzoom: 15,
                paint: {
                    "fill-extrusion-color": "#fff",

                    // Use an 'interpolate' expression to
                    // add a smooth transition effect to
                    // the buildings as the user zooms in.
                    "fill-extrusion-height": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        15,
                        0,
                        15.05,
                        ["*", ["get", "height"], 5],
                    ],
                    "fill-extrusion-base": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        15,
                        0,
                        15.05,
                        ["get", "min_height"],
                    ],
                    "fill-extrusion-opacity": 0.6,
                },
            },
            "waterway-label"
        );
    }

    render() {
        const {style} = this.props;
        return (
            <div>
                <div ref={this.mapContainer} className="map-container" style={style}/>
            </div>
        );
    }
}

class MapboxRegionSelector extends Component<IMapboxRegionSelectorProps,
    IMapboxRegionSelectorStates> {
    mapbox = React.createRef<Mapbox>();

    selectStart?: LngLat;
    selectEnd?: LngLat;
    selecting: boolean = false;
    markers: mapboxgl.Marker[];
    rectLayer = {
        id: "point",
        type: "fill",
        source: "point",
        layout: {},
        paint: {
            "fill-color": "#ff0000",
            "fill-opacity": 0.5,
        },
    };

    initialSource = {
        type: "geojson",
        data: {
            type: "Feature",
            geometry: {
                type: "Polygon",
                coordinates: [],
            },
        },
    };

    constructor(props: IMapboxRegionSelectorProps) {
        super(props);
        this.markers = [];
        this.state = {
            rainfall: 0,
            isCreating: false,
            jobName: "",
            presetData: [
                {
                    name: "test",
                    positions: [
                        [116.309, 39.9712],
                        [116.4134, 39.9044],
                    ],
                    recommended: true,
                },
            ],
            presetOptions: {recommended: [], custom: []},
        };
    }

    async componentDidMount() {
        this.mapbox.current!.map!.doubleClickZoom.disable();
        this.mapbox.current!.map!.dragRotate.disable();
        this.mapbox.current!.map!.touchZoomRotate.disable();
    }

    render() {
        const sider = (
            <Layout.Sider width={"17vw"} style={{backgroundColor: "white"}}>
                <Card style={{height: "80vh"}}>
                    <h2>设置{this.props.side === "left" ? "左" : "右"}侧场景</h2>
                    <Tabs
                        onTabClick={(key) => {
                            this.setState({jobName: ""});
                            if (key === "已有方案") this.setState({isCreating: false});
                            else if (key === "新建方案") this.setState({isCreating: true});
                        }}
                    >
                        <Tabs.TabPane tab={"已有模拟"} key={"已有方案"}>
                            <Typography>
                                <Typography.Title level={4}>已有方案</Typography.Title>
                                <Typography.Paragraph>
                                    查看历史的模拟结果。
                                </Typography.Paragraph>
                            </Typography>
                            <div style={{marginBottom: 20}}>
                                <h3>选取方案</h3>
                                <Select
                                    options={[
                                        {label: "暴雨-60mm", value: "baoyu_60"},
                                        {label: "特大暴雨-120mm", value: "baoyu_120"},
                                        {label: "10mm", value: "baoyu_10"}
                                    ]}
                                    style={{width: 200}}
                                    placeholder="选取方案"
                                    onSelect={(value: string) => {
                                        this.setState({jobName: value});
                                        if (this.props.onSelectComplete) {
                                            this.props.onSelectComplete(value);
                                        }
                                    }}
                                />
                            </div>

                        </Tabs.TabPane>
                        <Tabs.TabPane tab={"新建模拟"} key={"新建方案"}>
                            <Form onFinish={(values) => {
                                console.log(values);
                            }}>
                                <Typography style={{marginBottom: 20}}>
                                    <Typography.Title level={4}>新建方案</Typography.Title>
                                    <Typography.Paragraph>
                                        设置雨量，创建新的模拟，并查看模拟结果。
                                    </Typography.Paragraph>
                                </Typography>
                                <h3>雨量设置</h3>
                                <div style={{paddingLeft: 20, paddingRight: 20}}>
                                    <Form.Item name="rainfall" required={true}>
                                        <Slider
                                            tipFormatter={(value) => `${value} mm`}
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item name="planName" noStyle required={true}>
                                        <Input
                                            style={{width: 150}}
                                            placeholder="方案名称"
                                        />
                                    </Form.Item>
                                    <Form.Item noStyle>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            onClick={async () => {
                                                // let data = this.collectData() as any;
                                                // if (this.state.jobName) {
                                                //   data.name = this.state.jobName;
                                                //   data.recommended = false;
                                                // }
                                                // const res = await axios.post(
                                                //   "https://api.sim.tsingroc.com/add-plan",
                                                //   data
                                                // );
                                                // if (res.status === 200) {
                                                //   message.success("保存成功，请刷新页面");
                                                // } else if (res.status === 500) {
                                                //   message.error("命名重复");
                                                // }
                                            }}
                                        >
                                            保存方案
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </Tabs.TabPane>
                    </Tabs>
                </Card>
            </Layout.Sider>
        );
        return (
            <div>
                <Layout>
                    {this.props.side === "left" ? sider : <></>}
                    <Layout.Content>
                        <Mapbox
                            ref={this.mapbox}
                            lng={116.49524329043085}
                            lat={39.906120097057055}
                            zoom={10}
                            pitch={0}
                            buildingLayer={false}
                            externalLayers={[this.rectLayer]}
                            sources={[{name: "point", data: this.initialSource}]}
                            style={{height: "80vh", margin: 10}}
                        />
                    </Layout.Content>
                    {this.props.side === "right" ? sider : <></>}
                </Layout>
            </div>
        );
    }
}

export default MapboxRegionSelector;
