import React from "react";
import { Layout, Row, Col, Card } from "antd";
import { useHistory } from "react-router-dom";
import style from "./HomePage.module.css";

const HomePage: React.FC = () => {
  const history = useHistory();

  return (
    <Layout>
      <Layout.Header>
        <div style={{ float: "left" }}>
          <img src="/logo-com.png" height={40} alt="Logo" />
        </div>
        <div
          className="logo"
          style={{ color: "white", display: "inline-block" }}
        >
          {/*Simulet - Vis - v{displayVersion}*/}
        </div>
      </Layout.Header>
      <Layout.Content style={{ minHeight: "92vh" }}>
        <Card
          style={{
            marginTop: "10vh",
            marginLeft: "10vw",
            marginRight: "10vw",
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h1>选择模拟场景</h1>
          </div>

          <Card
            style={{
              height: "50vh",
              width: "50vw",
              justifyContent: "center",
              alignItems: "center",
            }}
            bodyStyle={{ padding: 0 }}
          >
            <Row>
              <Col span={12}>
                <Card.Grid
                  style={{
                    margin: 0,
                    width: "100%",
                    height: "50vh",
                    padding: 5,
                  }}
                >
                  <img
                    src="/面板-暴雨.png"
                    alt="暴雨"
                    style={{ height: "100%", width: "100%" }}
                    onClick={() => {
                      history.push("/by");
                    }}
                  />
                </Card.Grid>
              </Col>
              <Col span={12}>
                <Card.Grid
                  style={{
                    margin: 0,
                    width: "100%",
                    height: "50vh",
                    padding: 5,
                  }}
                >
                  <img
                    src="/面板-军事.png"
                    alt="军事"
                    style={{ height: "100%", width: "100%" }}
                    onClick={() => {
                      history.push("/js");
                    }}
                  />
                </Card.Grid>
              </Col>
            </Row>
          </Card>
        </Card>
      </Layout.Content>
    </Layout>
  );
};

export default HomePage;
