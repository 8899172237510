import React  from "react";
import { useRef } from "react";
import { Row, Col, Button, Layout } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import DestroyPointSelectView from "../Components/DestroyPointSelectView";

const SelectPointPage: React.FC<{}> = (props) => {
  const ref1 = useRef<DestroyPointSelectView>(null);
  const ref2 = useRef<DestroyPointSelectView>(null);
  const history = useHistory();
  const jobNames = useRef<[string?, string?]>([undefined, undefined]);

  return (
    <>
      <Layout>
        <Layout.Header>
          <div style={{ float: "left" }}>
            <img src="/logo-com.png" height={40} alt="Logo" />
          </div>
          <div
            className="logo"
            style={{ color: "white", display: "inline-block" }}
          >
            {/*Simulet - Vis - v{displayVersion}*/}
          </div>
          {/*<Button style={{ marginLeft: 100 }} type="link">*/}
          {/*  <a href="/sim">播放模拟</a>*/}
          {/*</Button>*/}
        </Layout.Header>
        <Layout.Content>
          <Row>
            <Col span={12}>
              <DestroyPointSelectView ref={ref1} side="left" onJobSelected={(jobName => jobNames.current[0] = jobName)}/>
            </Col>
            <Col span={12}>
              <DestroyPointSelectView ref={ref2} side="right" onJobSelected={(jobName => jobNames.current[1] = jobName)}/>
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 0,
              height: "10vh",
            }}
          >
            <Button
              icon={<PlayCircleOutlined />}
              type="primary"
              onClick={async () => {
                if (jobNames.current[0] && jobNames.current[1]) {
                  history.push(`/sim?job1=${jobNames.current[0]}&job2=${jobNames.current[1]}&norealtime=true`);
                }
                // history.push("/sim");
              }}
            >
              开始模拟
            </Button>
          </div>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default SelectPointPage;
